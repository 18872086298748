
import axios from 'axios'

export default function ({ $config }, inject) {
  const gearsEngageClient = axios.create({
    baseURL: $config.gearsVoyadoEngageApiBaseUrl,
    timeout: 30000,
    withCredentials: false
  })
  inject('gearsEngageClient', gearsEngageClient)
}
