import GearsVoyadoEngage from '~/mixins/gears-voyado-engage'

export default ($backendApi, context) => {
  return {
    mixins: [GearsVoyadoEngage],
    async addToCart (productSku, itemSize, quantity, productUrl) {
      const data = {
        productSku,
        itemSize,
        quantity,
        productUrl: `${this.$config.baseUrl}/${productUrl}`
      }

      const response = await $backendApi.post('/cart/add-item-by-sku', data, {
        headers: {
          selection: context.getSelectionId(),
        }
      })

      return response.data
    },
    async addBundleToCart (bundleId, sections) {
      const data = {
        bundleId,
        sections,
      }

      const response = await $backendApi.post('cart/bundle', data, {
        headers: {
          selection: context.getSelectionId(),
        }
      })

      return response.data
    },
    async removeBundleFromCart (lineId) {
      const response = await $backendApi.delete(`cart/bundle/${lineId}/remove`, {
        headers: {
          selection: context.getSelectionId()
        }
      })

      return response.data
    },
    async increaseQuantity (lineId, quantity) {
      const response = await $backendApi.put(`cart/bundle/${lineId}/add/${Number(quantity) || 1}`, null, {
        headers: {
          selection: context.getSelectionId()
        }
      })

      return response.data
    },
    async decreaseQuantity (lineId, quantity) {
      const response = await $backendApi.put(`cart/bundle/${lineId}/remove/${Number(quantity) || 1}`, null, {
        headers: {
          selection: context.getSelectionId()
        }
      })

      return response.data
    },
    async cartSubscribeToNewsletter (email) {
      // Engage newsletter subscription
      const response = await this.subscribeToEngageNewsletter(email)
      return response.data
    }
  }
}
