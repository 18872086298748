var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newsletter",class:[
    'bg-color--' + _vm.backgroundColor,
    'style--' + _vm.styleModifier
  ],style:([
    _vm.backgroundColorCss,
    _vm.foregroundColorCss
  ])},[(_vm.styleModifier === 'split' && _vm.image.filename)?_c('div',{staticClass:"image"},[_c('ResponsiveImage',{attrs:{"mobile-src":_vm.image.filename ? _vm.image.filename : '',"desktop-src":_vm.image.filename ? _vm.image.filename : '',"alt":_vm.alt ? _vm.alt : '',"mobile-display-size":"45vw","desktop-display-size":"45vw"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content di di--1"},[(_vm.headline)?_c('div',{staticClass:"headline",class:_vm.headlineSize,domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.headline.html))}}):_vm._e(),_vm._v(" "),(_vm.text)?_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.text.html)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","placeholder":_vm.placeholder},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_vm._v(" "),_c('button',{attrs:{"aria-label":_vm.buttonLabel,"disabled":_vm.loading},on:{"click":_vm.subscribe}},[_vm._v("\n        "+_vm._s(_vm.buttonLabel)+"\n      ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }