
const engageLocales = {
  FR: 'fr-FR',
  NL: 'nl-NL',
  BE: 'nl-BE',
  DE: 'de-DE',
  CH: 'de-CH',
  AT: 'de-AT',
  SE: 'sv-SE',
}

const engageLanguages = {
  FR: 'fr',
  NL: 'nl',
  BE: 'nl',
  DE: 'de',
  CH: 'de',
  AT: 'de',
  SE: 'sv',
}

export default {
  methods: {
    // Subscribe to engage newsletter, some fields are commented out as they are not used on the current site, only prepared for new site
    async subscribeToEngageNewsletter (email) {
      const market = this.$store.getters['frontend/market']
      const countryCode = this.$store.getters['frontend/currentCountryCode']
      try {
        const response = await this.$gearsEngageClient.post(`/v1/subscribe/${email}`, {
          market: market.name,
          contactType: 'member',
          countryCode,
          //   consents: [{
          //     id: 'consentAccountTerms', value: true // NOT used on current site, for new gears site
          //   }],
          preferences: {
            acceptsEmail: true
          },
          attributes: {
            // sportsActivity // not used for current site , will be used in new site
            lang: this.voyadoEngageLanguageMapper(),
          }

        })
        if (response?.data?.contactId) {
          this.voyadoEngageSetContactId(response.data.contactId)
        }
        return response.data
      } catch (error) {
        console.error('[Subscribe error]:', error)
        throw error
      }
    },

    // Tracking
    voyadoEngageSetContactId (contactId) {
      if (window?.va) {
        window.va('setContactId', contactId)
      } else {
        console.error('Voyado tracking script not properly loaded')
      }
    },
    voyadoEngageTrackProductView (product) {
      const category = product.canonicalCategoryUri
      const itemsSkuList = Object.values(product?.items).map(item => item?.sku)
      // use first item, engage need full sku, even though customer have not selected a specific size
      const sku = itemsSkuList?.[0]
      if (!sku) {
        console.error('No sku found for product')
        return
      }
      if (window?.va) {
        window.va('productview', {
          categoryName: category,
          itemId: sku,
          locale: this.voyadoEngageLocaleMapper()
        })
      } else {
        console.error('Voyado tracking script not properly loaded')
      }
    },

    voyadoEngageTrackCartUpdate (cart) {
      if (!window?.va) {
        console.error('Voyado tracking script not properly loaded')
        return
      }

      if (!cart) {
        console.error('No cart object provided')
        return
      }

      if (cart.items?.length === 0) {
        this.voyadoEngageTrackEmptyCart(cart.selection)
        return
      }
      const cartReviveUrl = this.$config.baseUrl + '/backend/cart/revive/' + cart.selection
      window.va('cart', {
        cartRef: cart.selection,
        cartUrl: cartReviveUrl,
        locale: this.voyadoEngageLocaleMapper(),
        items: Object.values(cart.items).map(cartItem => ({
          itemId: cartItem.sku,
          quantity: cartItem.quantity
        }))
      })
    },
    /**
       * This is to be ran everytime a cart is emptied by a purchase being made
       * selection needs to be the selection id from the cart that made the order
       */
    voyadoEngageTrackEmptyCart (selection) {
      if (window?.va) {
        window.va('emptyCart', {
          cartRef: selection
        })
      } else {
        console.error('Voyado tracking script not properly loaded')
      }
    },

    voyadoEngageLocaleMapper () {
      const countryCode = this.$store.getters['frontend/currentCountryCode']

      if (typeof countryCode === 'undefined') {
        return 'en-GB'
      }
      return engageLocales[countryCode] ?? 'en-GB'
    },

    voyadoEngageLanguageMapper () {
      const countryCode = this.$store.getters['frontend/currentCountryCode']

      if (typeof countryCode === 'undefined') {
        return 'en'
      }
      return engageLanguages[countryCode] ?? 'en'
    }

  },
}
