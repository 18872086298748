<template>
  <div
    class="newsletter"
    :class="[
      'bg-color--' + backgroundColor,
      'style--' + styleModifier
    ]"
    :style="[
      backgroundColorCss,
      foregroundColorCss
    ]"
  >
    <div
      v-if="styleModifier === 'split' && image.filename"
      class="image"
    >
      <ResponsiveImage
        :mobile-src="image.filename ? image.filename : ''"
        :desktop-src="image.filename ? image.filename : ''"
        :alt="alt ? alt : ''"
        mobile-display-size="45vw"
        desktop-display-size="45vw"
      />
    </div>
    <div class="content di di--1">
      <div
        v-if="headline"
        class="headline"
        :class="headlineSize"
        v-html="nl2br(headline.html)"
      />
      <div
        v-if="text"
        class="text"
        v-html="text.html"
      />
      <div class="input">
        <input
          v-model="email"
          type="email"
          :placeholder="placeholder"
        >
        <button
          :aria-label="buttonLabel"
          :disabled="loading"
          @click="subscribe"
        >
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ResponsiveImage from '~/components/image/ResponsiveImage.vue'
import ColorHexCode from '~/mixins/ColorHexCode.js'
import { nl2br } from '~/lib/helpers'
import GearsVoyadoEngage from '~/mixins/gears-voyado-engage'

export default {
  name: 'Newsletter',
  components: {
    ResponsiveImage
  },
  mixins: [ColorHexCode, GearsVoyadoEngage],
  props: {
    headline: {
      type: Object,
      required: false,
      default: null
    },
    headlineSize: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: Object,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    buttonLabel: {
      type: String,
      required: false,
      default: ''
    },
    styleModifier: {
      type: String,
      required: false,
      default: 'default'
    },
    image: {
      type: Object,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: ''
    },
    list: {
      type: String,
      required: false,
      default: ''
    },
    foregroundColor: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      email: '',
      loading: false
    }
  },
  computed: {
    foregroundColorCss () {
      return { '--foreground-color': this.foregroundColor }
    },
    backgroundColorCss () {
      const backgroundColor = this.color(this.backgroundColor, this.backgroundColorHex)
      return backgroundColor ? { backgroundColor } : ''
    }
  },
  methods: {
    nl2br,
    subscribe () {
      this.loading = true
      // Engage subscribe to newsletter
      this.subscribeToEngageNewsletter(this.email).then(() => {
        this.$notify({
          title: this.$t('Newsletter_Signup_SuccessHeader'),
          text: this.$t('Newsletter_Signup_SuccessMsg'),
          type: 'success'
        })

        this.loading = false
        this.email = ''
      }).catch(() => {
        this.loading = false

        this.$notify({
          title: this.$t('Newsletter_Signup_ErrorHeader'),
          text: this.$t('Newsletter_Signup_ErrorMsg'),
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter {
  padding: 2.4rem;
  color: var(--foreground-color, black);
  .image {
    display: none;
  }
  .content {
    .headline {
      margin: 0;
      &::v-deep {
        > br:last-of-type {
          display: none;
        }
        em {
          -webkit-text-stroke-color: var(--foreground-color, black);
        }
        p {
          color: var(--foreground-color, black);
          margin: 0;
        }
      }
    }
    .text {
      @include p--small;
      padding-bottom: 1.6rem;
    }
    .input {
      border-bottom: 1px solid var(--foreground-color, black);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      input,
      button {
        border: 0;
        background-color: transparent;
        outline: 0;
      }
      input {
        color: var(--foreground-color, black);
        @include p--large;
        line-height: 2rem;
        width: 70%;
        padding: 0;
        flex-shrink: 1;
        flex-grow: 0;
        padding: .8rem 0;
        &::placeholder {
          color: var(--foreground-color, black);
        }
      }
      button {
        @include button-reset;
        @include h--mini;
        margin: 0;
        text-align: right;
        width: 30%;
        flex-shrink: 0;
        flex-grow: 1;
        padding: .8rem 0;
      }
    }
  }
  &.style {
    &--split {
      text-align: center;
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .newsletter {
    .content {
      .text {
        @include p--medium;
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .newsletter {
    padding: 4rem;
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 4.8rem;
      align-items: center;
      .headline,
      .text,
      .input {
        text-align: left;
        vertical-align: middle;
      }
      .headline {
        line-height: 100%;
      }
      .text {
        padding-bottom: 0;
      }
      .input {
        height: 4rem;
      }
    }
    &.style {
      &--split {
        display: flex;
        align-items: center;
        padding: 0;
        .image {
          display: block;
          width: 45%;
        }
        .content {
          width: 55%;
          display: block;
          > * {
            text-align: center;
            margin: 0 10rem;
          }
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .newsletter {
    .content {
      grid-gap: 10rem;
      .text {
        &::v-deep p {
          @include p--large;
          margin: 0 !important;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $desktop) {
  .newsletter {
    .content {
      .text {
        padding: 3.2rem 0;
      }
    }
  }
}
</style>
