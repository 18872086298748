export const lastSelectionIdKey = 'last_selection_id'

export default {
  namespace: true,
  state () {
    return {
      lastSelectionId: ''
    }
  },
  mutations: {
    setLastSelectionId (state, id) {
      state.lastSelectionId = id
    }
  },
  actions: {
    initialize ({ commit }) {
      let lastSelectionId = ''
      if ('localStorage' in window) {
        lastSelectionId = localStorage.getItem(lastSelectionIdKey)
      } else {
        lastSelectionId = this.$cookies.get(lastSelectionIdKey)
      }
      commit('setLastSelectionId', lastSelectionId)
    },
    set ({ commit }, lastSelectionId) {
      commit('setLastSelectionId', lastSelectionId)
      if ('localStorage' in window) {
        localStorage.setItem(lastSelectionIdKey, lastSelectionId)
      } else {
        this.$cookies.set(lastSelectionIdKey, lastSelectionId)
      }
    }
  },
  getters: {
    lastSelectionId: state => state.lastSelectionId
  }
}
